:root {
  --min-width: 320px;
  --max-width: 1440px;
  --header-height: 96px;
}
.grid-container {
  --flap: minmax(var(--gap), 1fr);
  --content: minmax(var(--min-width), var(--max-width));

  display: grid;
  grid-template-columns:
    var(--flap)
    var(--content)
    var(--flap);
}
.grid-content {
  grid-column: 2 / 3;
}
.grid-content-full {
  grid-column: 1 / -1;
}