@font-face {
  font-family: eUkraineThin;
  src: url(../assets/fonts/e-Ukraine-Thin.eot);
  src: url(../assets/fonts/e-Ukraine-Thin.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Thin.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Thin.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Thin.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineUltraLight;
  src: url(../assets/fonts/e-Ukraine-UltraLight.eot);
  src: url(../assets/fonts/e-Ukraine-UltraLight.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-UltraLight.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-UltraLight.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-UltraLight.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineLight;
  src: url(../assets/fonts/e-Ukraine-Light.eot);
  src: url(../assets/fonts/e-Ukraine-Light.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Light.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Light.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineRegular;
  src: url(../assets/fonts/e-Ukraine-Regular.eot);
  src: url(../assets/fonts/e-Ukraine-Regular.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Regular.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Regular.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineHeadRegular;
  src: url(../assets/fonts/e-UkraineHead-Regular.eot);
  src: url(../assets/fonts/e-UkraineHead-Regular.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-UkraineHead-Regular.woff2) format("woff2"),
       url(../assets/fonts/e-UkraineHead-Regular.woff) format("woff"),
       url(../assets/fonts/e-UkraineHead-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

:root{
  --rem: 16px;
  --font-body:'eUkraineRegular';
  --font-heading:'eUkraineHeadregular';
  --font-regular:'eUkraineRegular';
  --font-light:'eUkraineLight';
  --font-ultra-light:'eUkraineUltraLight';
  --font-thin:'eUkraineThin';
  --font-monospace: monospace;
}

html{
  font-size: 1rem;
}
body{
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--font-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* HEADING */
h1, h2, h3, h4, h5, h6{
  font-family: var(--font-heading);
}
.heading-xl,
.heading-lg,
.heading-md,
.heading-sm,
.heading-xs{
  font-family: var(--font-heading);
}

.heading-xl {
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: bold;
}
.heading-lg {
  font-size: 2.375rem;
  line-height: 2.5rem;
  font-weight: bold;
}
.heading-md {
  font-size: 1.75rem;
  line-height: 2rem;
}
.heading-sm {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.heading-xs{
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 640px) {
  .heading-xl {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }
  .heading-lg {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .heading-md {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .heading-sm {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .heading-xs{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

/* BODY */
.body-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.body-md {
  font-size: 1rem;
  line-height: 1.5rem;
}
.body-sm {
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.body-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media screen and (max-width: 640px) {
  .body-lg {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .body-md {
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
  .body-sm {
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
  .body-xs {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}
.caption {
  padding: 0 1rem;
  letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
  color: var(--clr-caption);
}

/* COLORS */
.clr-secondary{
  color: var(--clr-secondary);
}
/* Status */
.clr-archive{
  color: var(--clr-archive);
}
.clr-published{
  color: var(--clr-published);
}
.clr-consideration{
  color: var(--clr-consideration);
}

/* FONTS */

.font-thin{
  font-family: var(--font-thin);
}
.font-heading{
  font-family: var(--font-heading);
}
.text-uppercase{
  text-transform: uppercase;
}

.gutter {
  margin-bottom: 1rem;
}
.text-ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
}