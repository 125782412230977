:root{
    --clr-primary:#113A36;
    --clr-secondary:#5F6037;
    --clr-accent:#63a52d;
    --clr-warn:#f44336;

    --clr-background: white;
    --clr-background-accent:#e8f0e1;
    --clr-background-hover:#dee7d4;
    --clr-background-active:#d5e7c2;

    --clr-text:black;
    --clr-text-invert:white;
    --clr-caption: #798371;

    --clr-link:#06c;

    --clr-green:#067D45;
    
    --clr-logo: white;

    --clr-chip:#c0d1b2;

    /* Status */
    --clr-archive:#5B5AFF;
    --clr-published: var(--clr-green);
    --clr-consideration: var(--clr-warn);

    /* GAP */
    --gap: 1rem;
    --gap-sm: 1.5rem;
    --gap-md: 2rem;


    /* WIDTH */
    --width-full: 100%;
    --width-panel: 320px;
    --width-panel-hide: 1000px;

    /* TRANSITION */
    --transition: all .2s ease;
}

html[data-theme='dark']{
    --clr-primary:#7cbebf;
    --clr-secondary:#9E9E9E;
    --clr-accent:#00e676;
    --clr-text: white;
    --clr-caption: var(--mdc-filled-text-field-label-text-color);
    --clr-background: #303030;
    --clr-background-accent:#393939;
    --clr-background-hover:#424242;;
    --clr-background-active:#515151;
    --clr-logo: #cee7e7;
    --clr-chip: #ccc;
    --clr-archive:#9090ff;
    --clr-published:#68d6a3;
    --clr-consideration: #ff9e97;
}