:root{
  --border: 1px solid var(--mat-table-row-item-outline-color);
}

/* cover-upload */
.cover-upload{
  width: 100%;
  max-width: 16.75rem;
  position: relative;
  order: 1;
}
.cover-upload .cover-preview {
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  position: relative;
  border-radius: 0.25rem;
  background-color:  var(--mdc-filled-text-field-container-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cover-upload .cover-preview mat-icon{
  font-size: 8rem;
  width: 8rem;
  height: 8rem;
  opacity: 0.26;
}
.cover-upload .cover-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  position: relative;
}
.cover-upload .cover-preview-btn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 3;
}

@media screen and (max-width: 720px) {
  .cover-upload {
      max-width: 100%;
      margin: 0 auto 1rem;
      order: 0;
  }
}  

/*---Mat table---*/
.section-table{
  overflow: auto;
  border-top:var(--border);
  border-bottom:var(--border);
}
.mat-column-actions{
  width: 128px;
  min-width: 128px;
  margin-left: auto;
}

/*---Mat tree---*/
.eco-tree-invisible {
  display: none;
}
.eco-tree ul,
.eco-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.eco-tree .mat-nested-tree-node div[role=group] {
  padding-left: 3rem;
}
.eco-tree div[role=group] > .mat-tree-node {
  padding-left: 3rem;
}
.eco-tree mat-tree-node{
  padding-left: 3rem;
  border-bottom: var(--border);
}
.eco-tree .mat-tree-node{
  border-bottom: var(--border);
}
.eco-tree div[role=group] .mat-tree-node,
.eco-tree div[role=group] mat-tree-node{
  border-image: linear-gradient(to right, transparent 0%, var(--mat-table-row-item-outline-color) 6%) 1;
}

/*---Mat paginator---*/
.section-paginator{
  border-bottom:var(--border);
}
.section-paginator mat-paginator .mat-mdc-paginator-container{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

/*---Mat card---*/
mat-card.eco-card{
  width: 100%;
  min-width: 320px;
  max-width: 960px;
  margin: 1rem auto;
  background: var(--clr-background-accent);
}
mat-card.eco-card mat-card-actions{
  position: sticky;
  bottom: 0;
  border-top:var(--border);
  background: var(--clr-background-accent);
  gap: 0.5rem;
  z-index: 8;
}
mat-card.eco-card .mat-mdc-form-field-subscript-dynamic-size{
	min-height:21px;
}

/*---Mat button---*/
button.mdc-button.btn-lg{
  height: 3rem;
}

.tabs mat-tab-body{
  min-height: calc(100vh - 7rem) !important;
}

/* Mat input */
input.mat-input-number::-webkit-outer-spin-button,
input.mat-input-number::-webkit-inner-spin-button {
  display: none;
}


/* eco-quill */
.eco-quill-editor{
  --clr-ql-active: var(--clr-accent);
  position: relative;
  display: flex;
  align-items: flex-start;
}
.eco-quill-editor::before{
  border-radius: 0.25rem 0.25rem 0 0;
}
.eco-quill-editor quill-editor{
  width: 100%;
  z-index: 2;
}
.eco-quill-editor .ql-toolbar.ql-snow{
  border-radius: 0.25rem 0.25rem 0 0;
  padding: var(--gap);
}
.eco-quill-editor .ng-star-inserted.ql-container.ql-snow{
  background: white;
  color: black;
}
.eco-quill-editor .ql-editor{
  padding:0.875rem 1rem;
}
.eco-quill-editor .ql-container{
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--font-light);
}

/*eco-quill header*/
html[data-theme='dark'] .eco-quill-editor .ql-header,
html[data-theme='dark'] .eco-quill-editor .ql-snow .ql-picker{
  color: var(--clr-text-invert);
}
html[data-theme='dark'] .eco-quill-editor .ql-snow .ql-stroke{
  stroke: var(--clr-text-invert);
}
html[data-theme='dark'] .eco-quill-editor .ql-snow .ql-fill,
html[data-theme='dark'] .eco-quill-editor .ql-snow .ql-stroke.ql-fill{
  fill: var(--clr-text-invert);
}
.eco-quill-editor .ql-snow a{
  color: var(--clr-link);
}
.eco-quill-editor .ql-snow .ql-tooltip a,
.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.eco-quill-editor .ql-snow.ql-toolbar button:hover,
.eco-quill-editor .ql-snow .ql-toolbar button:hover,
.eco-quill-editor .ql-snow.ql-toolbar button:focus,
.eco-quill-editor .ql-snow .ql-toolbar button:focus,
.eco-quill-editor .ql-snow.ql-toolbar button.ql-active,
.eco-quill-editor .ql-snow .ql-toolbar button.ql-active,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
  color: var(--clr-ql-active);
}
.eco-quill-editor .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.eco-quill-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter{
  stroke: var(--clr-ql-active);
}
.eco-quill-editor .ql-snow.ql-toolbar button:hover .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button:hover .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar button:focus .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button:focus .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar button.ql-active .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button.ql-active .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow.ql-toolbar .eco-quill-editor .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.eco-quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill{
  fill: var(--clr-ql-active);
}
.eco-quill-editor .ql-snow .ql-tooltip{
  background-color: var(--clr-background);
  color: var(--clr-text);
}
.eco-quill-editor .ql-snow .ql-picker-options{
  background-color: var(--clr-background);
}
.eco-quill-editor .ql-snow .ql-picker-label::before{
  line-height: 1.5rem;
}

/* eco-quill fonts */
.eco-quill-editor .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Введіть посилання:";
}
.eco-quill-editor .ql-snow .ql-tooltip a.ql-action::after{
  content: 'Вставити';
}
.eco-quill-editor .ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Видалити';
}
.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "Відео:";
}

.eco-quill-editor .ql-snow .ql-picker.ql-font{
  width: 9.5rem;
}
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-label::before,
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'e-Ukraine';
  font-family: var(--font-light);
}
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: 'e-Ukraine Head';
  font-family: var(--font-heading);
}
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
.eco-quill-editor .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: 'Monospace';
  font-family: var(--font-monospace);
}

.eco-quill-editor .ql-editor .ql-font-sans-serif{
  font-family: var(--font-light);
}
.eco-quill-editor .ql-editor .ql-font-serif{
  font-family: var(--font-heading);
}
.eco-quill-editor .ql-editor .ql-font-monospace{
  font-family: var(--font-monospace);
}