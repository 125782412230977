/* You can add global styles to this file, and also import other style files */
@import "~material-icons/iconfont/material-icons.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body { height: 100%; }
