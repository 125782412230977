html[data-theme='light'] mat-chip-row::before{
  border-radius: 2rem;
}
html[data-theme='light'] .background-shade::before,
html[data-theme='light'] mat-chip-row::before,
html[data-theme='light'] mat-form-field .mdc-text-field::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background-color: var(--clr-accent);
  opacity: 0.26;
}
html[data-theme='light'] mat-form-field.search .mdc-text-field::before,
mat-paginator .mat-mdc-form-field .mdc-text-field::before{
  display: none;
}
