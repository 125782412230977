.section-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding: var(--gap) 0;
}

/* Section action */
.section-breadcrumbs,
.section-action{
  display: flex;
  align-items: center;
  gap: var(--gap);
  padding:0 var(--gap);
  min-height: 4rem;
}
.section-action .search{
  margin-left: auto;
}
@media screen and (max-width: 640px) {
  .section-action {
    flex-direction: column;
    align-items: stretch;
    padding-top: var(--gap);
    padding-bottom: var(--gap);
  }
  .section-action mat-button-toggle-group{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .section-action .search{
    margin-left: 0;
  }
}

/* Section edit */
.section-row{
  display: flex;
  gap: var(--gap) var(--gap-md);
  flex-wrap: wrap;
  flex: 1;
}
.section-col{
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  flex: 1;
}
.flex-start{
  align-self: flex-start;
}
.chip {
  padding: 0 1rem;
  margin-right: auto;
  display: flex;
  align-items: center;
  min-height: 2rem;
  border-radius: 2rem;
  background-color: var(--clr-chip);
}

mat-form-field.flex-1{
  flex: 1;
  min-width: 13rem;
  max-width: calc(50% - 0.5 * var(--gap));
}

@media screen and (max-width: 720px) {
  .section-row {
    flex-direction: column;
  }
  mat-form-field.flex-1{
    max-width: 100%;
  }
}